<template>
  <div id="NavHeader">
    <div class="nav">
      <div class="Nav_Left">
        <div class="logo" @click="jump('/')">
          <!-- <img
            src="@/assets/images/NavHeader/logo.png"
            style="width:100px;height:100px"
            alt=""
          /> -->
		  <p>成都志嘉励合科技有限公司</p>
        </div>
        <div class="nav-box">
          <div
            class="item"
            @click="jump('/')"
            :class="{'active' : this.$route.path == '/Home'}"
          >
            <p><img src="@/assets/images/NavHeader/nav1.png" alt="" />首页</p>
            <i></i>
          </div>

          <!-- <div class="item" @click="jump('/BoxFight')" :class="{'active' : this.$route.path == '/BoxFight'}">
						<p><img style="width: 30px;height: 30px" src="@/assets/images/NavHeader/Dz.png" alt="" />盲盒对战</p>
						<i></i>
					</div> -->

          <div
            class="item"
            @click="jump('/Activity')"
            :class="{'active' : this.$route.path == '/Activity'}"
          >
            <p>
              <img src="@/assets/images/NavHeader/nav2.png" alt="" />
              免费白嫖
            </p>
            <i></i>
          </div>

          <div
            class="item"
            @click="jump('/Replacement')"
            :class="{'active' : this.$route.path == '/Replacement'}"
          >
            <p>
              <img src="@/assets/images/NavHeader/nav3.png" alt="" />炼金合同
            </p>
            <!-- <span><img src="@/assets/images/NavHeader/hot.png" alt=""></span> -->
            <i></i>
          </div>

          <!-- <div class="item" @click="jump('/Roll')" :class="{'active' : this.$route.path == '/Roll'}">
						<p>
							<img src="@/assets/images/NavHeader/nav4.png" alt="" />
							ROLL福利
						</p>
						<i></i>
					</div> -->

          <div
            class="item"
            @click="jump('/Lucky')"
            :class="{'active' : this.$route.path == '/Lucky'}"
          >
            <p>
              <img src="@/assets/images/NavHeader/nav9.png" alt="" />
              追梦宝箱
            </p>
            <i></i>
          </div>

          <!-- <div class="item" @click="jump('/JewelryMall')" :class="{'active' : this.$route.path == '/JewelryMall'}">
						<p>
							<img src="@/assets/images/NavHeader/nav5.png" alt="" />
							饰品商城
						</p>
						<i></i>
					</div> -->

          <div
            class="item"
            @click="jump('/Recharge')"
            :class="{'active' : this.$route.path == '/Recharge'}"
          >
            <p>
              <img src="@/assets/images/NavHeader/nav6.png" alt="" />
              账户充值
            </p>
            <i></i>
          </div>

          <!-- <div class="item" @click="jump('/Extension')" :class="{'active' : this.$route.path == '/Extension'}">
						<p><img src="@/assets/images/NavHeader/nav7.png" alt="" />
							推广合作</p>
						<i></i>
					</div> -->

          <!-- <div class="item" @click="jump('/VIP')" :class="{'active' : this.$route.path == '/VIP'}">
						<p><img src="@/assets/images/NavHeader/nav8.png" alt="" />
							会员中心</p>
						<i></i>
					</div> -->

          <!--					<div class="item" @click="jump('/Activity')" :class="{'active' : this.$route.path == '/Replacement'}">-->
          <!--						<p>-->
          <!--							<img src="@/assets/images/NavHeader/nav7.png" alt="" />-->
          <!--							福利箱-->
          <!--						</p>-->
          <!--						<i></i>-->
          <!--					</div>-->

          <!--					<div class="item" @click="jump('/Warehouse')" :class="{'active' : this.$route.path == '/Warehouse'}">-->
          <!--						<p>-->
          <!--							<img src="@/assets/images/NavHeader/nav8.png" alt="" />-->
          <!--							会员推广-->
          <!--						</p>-->
          <!--						<i></i>-->
          <!--					</div>-->

          <!-- <div class="item" @click="jump('/RushPurchase')" :class="{'active' : this.$route.path == '/RushPurchase'}">
					<p>抢卡</p>
					<i></i>
				</div> -->
        </div>
      </div>
      <div class="personal">
        <div class="Sign" v-show="this.IndividualsShow == false">
          <div class="Sign-item" @click="PostRegisterShow">
            <p>注册</p>
          </div>
          <div class="Sign-item" @click="PostSignInShow">
            <p>登录</p>
          </div>
        </div>

        <div class="per" v-show="this.IndividualsShow == true">
          <!--					<p><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{user.bean}}</p>-->
          <!--					<p @click="jump('/Extension')" style="color: #ffd322;"><img style="width: 64px;height: 54px;"-->
          <!--							src="@/assets/images/NavHeader/wan_vip.png" alt="">-->
          <!--						&lt;!&ndash; {{user.promotion_level}} &ndash;&gt;-->
          <!--					</p>-->
          <div class="user" @click="jump('/User')">
            <img class="user-img" :src="user.avatar" alt="" />
            <div class="">
              <span>{{user.name}}</span>
              <div
                class="d-flex align-items-center"
                style="color: #ECB105;margin-top: 5px"
              >
                <img
                  style="width:20px;height: 20px;margin-right:5px;"
                  src="@/assets/images/PublicImg/JinBi.png"
                  alt=""
                />{{user.bean}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="NavHeader-bg"></div>
    <swiper></swiper>
    <div class="XiangZi_Box" v-if="false">
      <div
        class="XiangZi_item"
        v-for="(item,index) in Box_Arr"
        :ket="index"
        @click="ShowNeiRong(item)"
      >
        <div class="pic">
          <img :src="item.cover" alt="" />
        </div>
        <span
          ><img
            src="@/assets/images/PublicImg/JinBi.png"
            alt=""
          />{{item.game_bean}}</span
        >
        <p>{{item.name}}</p>
      </div>
    </div>
    <!-- 		<div class="data_info">
			<div class="data_info_item">
				<img src="@/assets/images/NavHeader/ZX.png" alt="">
				<p><span style="color:#00d5c6;">{{SuiJiNum}}</span>在线</p>
			</div>
			<div class="data_info_item">
				<img src="@/assets/images/NavHeader/YH.png" alt="">
				<p><span>{{info.user_number}}</span>用户</p>
			</div>
			<div class="data_info_item">
				<img src="@/assets/images/NavHeader/XZ.png" alt="">
				<p><span>{{info.open_box_number}}</span>武器箱</p>
			</div>
		</div> -->

    <!-- 注册弹框 -->
    <van-overlay :show="RegisterShow">
      <div class="Wrapper" v-if="RegisterShow">
        <div class="out">
          <!-- <div class="Ren-1">

					</div> -->
          <div class="DZ-box" :style="W_BL | STY">
            <p>注册</p>
            <el-form
              :model="RegisterForm"
              status-icon
              ref="RegisterForm"
              label-width="90px"
              class="demo-ruleForm"
            >
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="RegisterForm.mobile"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="RegisterForm.password"
                ></el-input>
              </el-form-item>
              <el-form-item label="用户名" prop="name">
                <el-input v-model="RegisterForm.name"></el-input>
              </el-form-item>
              <el-form-item label="验证码" class="YZM-box" prop="verify">
                <el-input v-model="RegisterForm.verify"></el-input>
                <div
                  class="YZM"
                  @click="GetYanZhengMa(1)"
                  v-show="VerificationShow == false"
                >
                  发送验证码
                </div>
                <div class="DJS" v-show="VerificationShow == true">
                  <div class="XXX">已发送</div>
                  <van-count-down
                    :time="time"
                    class="CLR"
                    format="ss"
                    :auto-start="false"
                    @finish="finish"
                    ref="countDown"
                  />
                </div>
              </el-form-item>
              <el-form-item label="邀请码">
                <el-input v-model="RegisterForm.invite"></el-input>
              </el-form-item>

              <div class="XY_box">
                <el-checkbox v-model="XYchecked"
                  >我接受《用户条款和隐私政策》</el-checkbox
                >
              </div>

              <el-form-item>
                <div class="btn-box">
                  <div class="btn" @click="RegisterQX">取消</div>
                  <div class="btn" @click="PostRegister">注册</div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 登录弹框 -->
    <van-overlay :show="SignInShow">
      <div class="Wrapper" v-if="SignInShow">
        <div class="out">
          <div class="Ren"></div>
          <div class="DZ-box" :style="W_BL | STY" style="padding-top: 20px;">
            <div style="display: flex; justify-content: space-around;">
              <p class="" style="cursor: pointer;" @click="PWCode = true">
                账号密码登录
              </p>
              <p class="" style="cursor: pointer;" @click="PWCode = false">
                验证码登录
              </p>
            </div>
            <el-form
              :model="SignInForm"
              status-icon
              ref="SignInForm"
              label-width="90px"
              class="demo-ruleForm"
            >
              <el-form-item prop="mobile" label="手机号">
                <el-input v-model="SignInForm.mobile"></el-input>
              </el-form-item>
              <el-form-item v-if="PWCode" prop="password" label="密码">
                <el-input
                  type="password"
                  v-model="SignInForm.password"
                ></el-input>
              </el-form-item>
              <el-form-item v-else label="验证码" class="YZM-box" prop="verify">
                <el-input v-model="SignInForm.verify"></el-input>
                <div
                  class="YZM"
                  @click="GetYanZhengMa(2)"
                  v-show="VerificationShow == false"
                >
                  发送验证码
                </div>
                <div class="DJS" v-show="VerificationShow == true">
                  <div class="XXX">已发送</div>
                  <van-count-down
                    :time="time"
                    class="CLR"
                    format="ss"
                    :auto-start="false"
                    @finish="finish"
                    ref="countDown"
                  />
                </div>
              </el-form-item>
              <div class="remember_box">
                <el-checkbox v-model="checked">记住密码</el-checkbox>
                <p @click="GetChangePassword">忘记密码</p>
              </div>
              <el-form-item>
                <div class="btn-box">
                  <div class="btn" @click="SignInQX">取消</div>
                  <div class="btn" @click="PostSignIn">登录</div>
                </div>
              </el-form-item>
              <!-- <div class="Steam">
								<p>-其他方式-</p>
								<div class="img_box"></div>
							</div> -->
            </el-form>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 箱子遮罩层 -->
    <van-overlay :show="XZshow">
      <div class="wrapper">
        <div class="block">
          <div class="block-T">
            <p>{{Box_Name}}</p>
            <span @click="CloseXZshow">×</span>
          </div>
          <div class="Box-List">
            <div
              class="Box-List-item"
              v-for="(item,index) in JiangPin_Arr"
              :key="index"
            >
              <div class="pic">
                <div class="zhao">
                  <img :src="item.lv_bg_image" alt="" />
                </div>
                <img :src="item.cover" alt="" />
              </div>
              <div class="name">{{item.name | GuoLv}}</div>
              <div class="money">
                <img
                  src="@/assets/images/PublicImg/JinBi.png"
                  alt=""
                /><span>{{item.bean}}</span>
              </div>
            </div>
          </div>
          <div class="boxbtn">
            <p @click="CloseXZshow">确定</p>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 排行 -->
    <Rank v-show="true" />
  </div>
</template>

<script>
	import {
		Register,
	PostSendSms,
		SignIn,
		PersonalInfo,
		Arena_Box_List,
		Info
	} from '@/network/api.js'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import Swiper from '@/components/common/NavHeader/Swiper.vue'
	import Rank from '@/views/Rank/index.vue'
	export default {
		name: 'NavHeader',
		data() {
			return {
				W_BL:false,
				Box_Name:'',
				time: 60 * 1000,
				VerificationShow: false,
				Box_Arr: [],
				checked: true,
				XYchecked:true,
				JiangPin_Arr:[],

				Box_Arr_Show:false,

				XZshow:false,

				RegisterForm: {
					mobile: '',
					password: '',
					name: '',
					verify: '',
					invite: ''
				},

				SignInForm: {
					mobile: '',
					password: '',
					verify:''
				},
				SuiJiNum:0,
				info:{},
				// 验证码密码切换
				PWCode:true
			}
		},

		created() {

			// console.log(this.$route)
			if(window.screen.width < 750){
				this.W_BL = true
			}else{
				this.W_BL = false
			}

			if(this.$route.path == '/BoxFight' || this.$route.path == '/vsbox' || this.$route.path == '/CreateAConfrontation'){
				this.Box_Arr_Show = true
			}
			this.RegisterForm.invite = window.location.search.substr(5)
			this.PanDuanDengLu()
			this.Get_Arena_Box_List()
			this.GetInfo()
		},


		filters:{
			GuoLv(str){
				return str.length > 12 ? str.substr(0,10) + '...' : str
			},
			STY(val){
				// console.log(val)
				if(val == true){
					return 'width:80%;'
				}else{
					return 'width:5rem;'
				}
			}
		},

		activated() {

		},



		methods: {
			//初次进入判断是否记住登录
			PanDuanDengLu() {
				if (sessionStorage.getItem('token')) {
					this.GetPersonalInfo()
				} else {
					if (localStorage.getItem('token')) {
						sessionStorage.setItem('token', localStorage.getItem('token'))
						this.GetPersonalInfo()
					} else {
						this.GetIndividualsShow(false)
					}
				}

			},

			//站点信息
			GetInfo(){
				Info().then((res) => {
					this.info = res.data.data
					// console.log(res.data.data)
					localStorage.setItem('static_file_url',res.data.data.static_file_url)
					this.GetSuiJi()
				})
			},

			GetSuiJi(){
				this.SuiJiNum = Math.floor(Math.random() * (50 - Number(this.info.user_number))) + Number(this.info.user_number)
			},


			CloseXZshow(){
				this.XZshow = false
			},

			//暂未开放
			WuKaiFang(){
				this.$message({
					message: '暂未开放,敬请期待!',
					type: 'error'
				})
			},

			//查看箱子内容
			ShowNeiRong(item){
				// console.log(item)
				this.JiangPin_Arr = item.box_awards
				this.Box_Name = item.name
				this.XZshow = true

			},

			//获取宝箱列表
			Get_Arena_Box_List() {
				Arena_Box_List().then((res) => {
					// console.log(res)
					this.Box_Arr = res.data.data
				})
			},

			//路由跳转
			jump(str) {
				this.$router.push({
					path: str
				}).catch(err => {
					this.$router.go(0)
				})
			},

			//修改密码
			GetChangePassword() {

				this.$router.push('/ChangePassword')
				this.GetSignInShow(false)
			},

			//注册
			PostRegisterShow() {
				this.GetRegisterShow(true)
			},

			//注册操作
			PostRegister() {
				if(this.XYchecked){
					Register(this.RegisterForm.mobile, this.RegisterForm.password, this.RegisterForm.name, this.RegisterForm.verify,
					this.RegisterForm.invite).then((res) => {
						// console.log(res)
						if (res.data.code == 200) {
							this.$message({
								message: '注册成功',
								type: 'success'
							})
							this.GetRegisterShow(false)
							this.EmptyForm()
						} else {
							this.$message({
								message: res.data.message,
								type: 'error'
							})
						}
					})
				}else{
					this.$message({
						message:'请先勾选用户协议',
						type: 'error'
					})
				}

			},

			//发送手机号验证码
			GetYanZhengMa(num) {
				if (this.RegisterForm.mobile || this.SignInForm.mobile) {
					this.VerificationShow = true
					this.$refs.countDown.start();
					PostSendSms(this.RegisterForm.mobile || this.SignInForm.mobile, num).then((res) => {
						// console.log(res)
						if (res.data.code == 200) {
							this.$message({
								message: '发送成功',
								type: 'success'
							})
						} else {
							this.$message({
								message: res.data.message,
								type: 'error'
							})
						}
					})
				} else {
					this.$message({
						message: '请输入手机号',
						type: 'error'
					})
				}

			},

			finish() {
				//重置倒计时
				this.VerificationShow = false
				this.$refs.countDown.reset();
			},

			//取消注册
			RegisterQX() {
				this.GetRegisterShow(false)
				this.EmptyForm()
			},


			//登录
			PostSignInShow() {
				this.GetSignInShow(true)
			},


			//登录操作
			PostSignIn() {
				SignIn(this.SignInForm.mobile, this.SignInForm.password, this.SignInForm.verify).then((res) => {
					// console.log(res)
					if (res.data.code == 200) {
						this.$message({
							message: '登录成功',
							type: 'success'
						})
						if (this.checked == true) {
							localStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
							sessionStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
						} else {
							sessionStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
						}
						this.GetSignInShow(false)
						this.GetPersonalInfo()
						this.EmptySignInForm()
					} else {
						this.$message({
							message: res.data.message,
							type: 'error'
						})
					}
				})

		},

		//个人信息
		GetPersonalInfo() {
			PersonalInfo().then((res) => {
				// console.log(res.data.message)
				this.User(res.data.message)
				this.GetIndividualsShow(true)
				// console.log(res.data.message)
			})
		},

		//取消登录
		SignInQX() {
			this.EmptySignInForm()
			this.GetSignInShow(false)
		},


		//个人下拉菜单
		handleCommand(command) {
			switch (command) {
				case 'out':
					this.SignOut()
					break;
				default:
					this.$router.push(command)
			}

		},



		//退出
		SignOut() {
			this.GetIndividualsShow(false)
			this.Leave()
		},

		//清空注册表单
		EmptyForm() {
			this.RegisterForm.email = ''
			this.RegisterForm.pass = ''
			this.RegisterForm.name = ''
			this.RegisterForm.verify = ''
			this.RegisterForm.invite = ''
		},

		//清空登录表单
		EmptySignInForm() {
			this.SignInForm.email = ''
			this.SignInForm.pass = ''
		},

		//路由跳转充值
		ToRecharge() {
			this.$router.push('/Recharge')
		},

		...mapMutations([
			'User',
			'GetIndividualsShow',
			'Leave',
			'GetRegisterShow',
			'GetSignInShow'
		])

	},

	computed: {
		...mapState([
			'user',
			'IndividualsShow',
			'RegisterShow',
			'SignInShow'
		])
	},

	watch: {
		Email_Name(val) {
			this.Email_Name = val
		},

		$route(to, from) {
			// console.log(to.path)
			if (to.path == '/BoxFight' || to.path == '/vsbox' || to.path == '/CreateAConfrontation') {
				this.Box_Arr_Show = true
			} else {
				this.Box_Arr_Show = false
			}
		}
	},

	components: {
		Swiper,
	}
	// components: {
	// 	Swiper,
	// 	Rank
	// }
}
</script>

<style lang="scss">
#NavHeader {
		width: 100%;
		background:rgba(120,120,120,.6);
		position: relative;
		.XiangZi_Box {
			width: 100%;
			display: flex;
			.XiangZi_item {
				width: 1.92rem;
				height: 2.3rem;
				background: rgba(25, 25, 25, 0.6);
				border: 1px solid #2b2b2b;
				margin-left: -1px;
				cursor: pointer;
				text-align: center;
				position: relative;
				box-sizing: border-box;
				.pic {
					width: 1.6rem;
					height: 1.6rem;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						vertical-align: middle;
						max-width: 80%;
						max-height: 80%;
					}

				}
				span{
					img{
						width: 0.2rem;
						height: 0.2rem;
						vertical-align: middle;
					}
					font-size: 0.14rem;
					color: #ffd300;
				}
				p {
					margin-top: 0.08rem;
					font-size: 0.14rem;
				}
				&:hover{
					border-color: #ffd300;
					z-index: 5;
					p{
						color: #ffd300;
					}
				}
			}
		}



		.nav {
			width: 100%;
			height:.9rem;
			padding-left: 0.2rem;
			background: #191C34;
			background-size: 100% auto;
			position: fixed;
			left: 0;
			top:0;
			z-index: 99;
			box-sizing: border-box;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			.Nav_Left{
				width: 75%;
				display: flex;
				align-items: center;
				.logo {
					width: 4rem !important;
					cursor: pointer;
					// margin-right: 1rem;

					img {
						width: 3rem;
					}
				}

				.nav-box {
					width: 12rem;
					box-sizing: border-box;
					display: flex;
					align-items: center;

					.item {
						width: 2.8rem;
						height: .9rem;
						line-height: .9rem;
						box-sizing: border-box;
						text-align: center;
						cursor: pointer;
						position: relative;
						margin-right: 0.2rem;
						position: relative;
						display: flex;
						justify-content: center;
						p {
							color: #fff;
							font-size: 0.14rem;
							display: flex;
							align-items: center;
							& img{
								width: .2rem;
								margin-right: .05rem;
							}
						}

						i {
							display: block;
							width: 100%;
							height: 0.04rem;
							position: absolute;
							background: #7581EB;
							left: 50%;
							bottom: 0;
							transform: translateX(-50%);
							opacity: 0;
						}
						&:hover{
							background: rgba($color: #fff, $alpha: .03);
						}
						&.active{
							background: #232749;
							i{
								opacity: 1;
							}
						}
					}
				}
			}


			.personal {
				width: 3rem;
				height:100%;
				.per {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: flex-end;
					cursor: pointer;
					padding-right: 30px;
					background-size: 100% 100%;
					background-image: url("../../../assets/images/NavHeader/user-top.png");
					p{
						width: 1.28rem;
						height: 100%;
						text-align: center;
						color: #75cd66;
						font-size: .18rem;
						font-weight: bold;
						border-left: 1px solid rgba($color: #fff, $alpha: .08);
						margin: 0;

						img{
							display: block;
							margin: .2rem auto .05rem;
							width: 24px;
							height: 24px;
						}
					}
					.user{
						width:100%;
						height: 100%;
						padding-left: 30px;
						display: flex;
						align-items: center;
						.user-img{
							display: block;
							width: .7rem;
							height:.7rem;
							border-radius: 50%;
							border: 1px solid #ffba2e;
							margin: 0.05rem .1rem;
						}
						span{
							font-size: .14rem;
						}
					}

				}

				.Sign {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					.Sign-item {
						width: .8rem;
						height: 0.3rem;
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						background:#ffd322;
						border-radius: 0.04rem;
						text-align: center;
						line-height: 0.3rem;
						color: #000;

						&:first-child{
							margin-right:0.08rem;

						}

						p {
							font-size: 0.16rem;
						}
					}
				}
			}
		}
		.data_info{
			width: 100%;
			padding: 0 0.6rem 0.2rem;
			box-sizing: border-box;
			height: 0.6rem;
			display: flex;
			align-items: center;
			.data_info_item{
				display: flex;
				align-items: center;
				height: 0.6rem;
				margin-right: 0.2rem;
				img{
					height: 0.4rem;
					margin-right: 0.05rem;
				}
				p{
					font-size: 0.22rem;
					color: #a99bc5;
					span{
						color: #ea4391;
						margin-right: 0.04rem;
					}
				}
			}
		}


		.Wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			position: relative;
			padding-top: 150px;
			.out{
				display: flex;
				width: 4.5rem;

			}
			.DZ-box {
				width: 5rem !important;
				/*background: #232749;*/
				color: #fff;
				position: relative;
				/*border-top: 3px solid #7581EB;*/
				padding: 20px;
				background-image: url(../../../assets/images/public/login.png) ;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				&::before{
					position: absolute;
					content: "";
					top: -2.6rem;
					left: -1.5rem;
					width: 6.6rem !important;
					height: 3.5rem;
					background-image: url(../../../assets/images/public/toplogin.png) ;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					z-index: -1;
				}

				// &::after{
				// 	display: block;
				// 	content: "";
				// 	width: 3rem;
				// 	height: 100%;
				// 	position: absolute;
				// 	left: -2.5rem;
				// 	top: 0;
				// 	background: url(../../../assets/images/public/name.webp) no-repeat center;
				// 	background-size: contain;
				// }

				p {
					font-size: 0.22rem;
					text-align: center;
					margin-top: 0.2rem;
				}

				.Steam {
					width: 100%;
					padding-left: 0.9rem;
					text-align: center;
					box-sizing: border-box;
					margin-bottom: 0.2rem;

					p {
						font-size: 0.14rem;
						margin: 0;
					}

					.img_box {
						width: 0.4rem;
						height: 0.4rem;
						margin: 0.2rem auto 0;
						background: url(../../../assets/images/NavHeader/steam.png) no-repeat center;
						background-size: cover;
						cursor: pointer;

						&:hover {
							background: url(../../../assets/images/NavHeader/steamClick.png) no-repeat center;
							background-size: cover;
						}
					}
				}

				.el-form {
					width: 90%;
					margin-top: 0.2rem;

					.remember_box {
						width: 100%;
						height: 0.2rem;
						display: flex;
						padding-left: 0.9rem;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						margin-bottom: 0.2rem;

						p {
							line-height: 0;
							margin-top: 0;
							font-size: 0.14rem;
							cursor: pointer;

							&:hover {
								color: red;
							}
						}

						.el-checkbox__input.is-checked+.el-checkbox__label {
							color: #ffd322;
						}

						.el-checkbox__input.is-checked .el-checkbox__inner,
						.el-checkbox__input.is-indeterminate .el-checkbox__inner {
							border-color: #ffd322;
							background: #ffd322;
						}

						.el-checkbox {
							color: #000;
							font-size: 0.14rem;
						}
					}

					.el-form-item__label {
						color: #fff;
						font-size: 0.14rem;
					}

					.el-input {
						.el-input__inner {
							background: #fff;
							border: none;
							color: #000;
						}
					}
				}

				.YZM-box {
					.el-form-item__content {
						display: flex;
						align-items: center;

						.el-input {
							width: 70%;
						}

						.YZM {
							width: 1rem;
							height: 0.38rem;
							margin-left: 0.3rem;
							font-size: 0.14rem;
							line-height: 0.38rem;
							text-align: center;
							background: #ffd322;
							cursor: pointer;
							color: #000;
						}

						.DJS {
							width: 1rem;
							height: 0.38rem;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 0.3rem;
							background: #ffd322;

							.XXX {
								height: 0.38rem;
								font-size: 0.14rem;
								margin-right: 0.05rem;
								box-sizing: border-box;
								margin-bottom: 0.02rem;
							}

							.CLR {
								font-size: 0.14rem;
								color: #000;
							}
						}
					}
				}

				.btn-box {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					.btn {
						width: 50%;
						height: 0.4rem;
						background: #ffd322;
						color: #000;
						text-align: center;
						line-height: 0.4rem;
						margin: 0 0.1rem;
						border-radius: 0.04rem;
						cursor: pointer;
					}
				}

				.XY_box{
					width: 100%;
					display: flex;
					justify-content: center;
					margin-bottom: 20px;

					.el-checkbox__label{
						color:#ffd322 ;
					}
					.el-checkbox__input.is-checked .el-checkbox__inner,
					.el-checkbox__input.is-indeterminate .el-checkbox__inner {
						border-color: #ffd322;
						background: #ffd322;
					}
				}
			}
		}

		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.block {
			width: 8rem;
			height: 6.7rem;
			background-color: #212529;
			border-radius: 0.06rem;
			padding: 0.2rem;
			box-sizing: border-box;

			.block-T {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				p {
					font-size: 0.24rem;
					color: #fff;
				}

				span {
					font-size: 0.28rem;
					color: rgba($color: #ffffff, $alpha: .6);
					cursor: pointer;
				}
			}

			.Box-List {
				width: 100%;
				height: 5rem;
				margin: 0.2rem 0 0.4rem;
				overflow: auto;
				display: flex;
				flex-wrap: wrap;

				.Box-List-item {
					width: 1.8rem;
					height: 2.3rem;
					position: relative;
					background: rgba(0, 0, 0, .2);
					border-radius: 0.04rem;
					margin-right: 0.1rem;
					margin-bottom: 0.1rem;
					cursor: pointer;
					overflow: hidden;

					&:nth-child(4n) {
						margin-right: 0;
					}

					&:hover {
						.ceng {
							opacity: 1;
						}
					}

					.pic {
						width: 1.8rem;
						height: 1.5rem;
						margin: 0.1rem auto 0;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;

						.zhao {
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						img {
							max-width: 100%;
							max-height: 100%;
							position: relative;
							z-index: 10;
						}
					}

					.name {
						width: 100%;
						text-align: center;
						color: #9e9e9e;
						font-size: 0.14rem;
					}

					.money {
						width: 100%;
						margin-top: 0.1rem;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							width: 0.2rem;
							height: 0.2rem;
							vertical-align: middle;
						}

						span {
							font-size: 0.14rem;
							color: #ffd300;
						}
					}
				}
			}

			.Box-List::-webkit-scrollbar {
				/*滚动条整体样式*/
				width: 4px;
				/*高宽分别对应横竖滚动条的尺寸*/
				height: 4px;
				scrollbar-arrow-color: red;

			}

			.Box-List::-webkit-scrollbar-thumb {
				/*滚动条里面小方块*/
				border-radius: 5px;
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background: rgba(0, 0, 0, 0.6);
				scrollbar-arrow-color: red;
			}

			.Box-List::-webkit-scrollbar-track {
				/*滚动条里面轨道*/
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				border-radius: 0;
				background: rgba(0, 0, 0, 0.1);
			}

			.boxbtn {
				width: 100%;
				height: 0.35rem;
				display: flex;
				justify-content: flex-end;

				p {
					width: .7rem;
					height: .35rem;
					background: red;
					font-size: 0.14rem;
					color: #fff;
					text-align: center;
					line-height: 0.35rem;
					cursor: pointer;
				}

			}
		}
	}
	.NavHeader-bg{
		margin-top: 0.9rem;
		// width: 100%;
		// height: 2.5rem;
		// background-size: 100%;
		// background-repeat: no-repeat;
		// background-position: center;
		// background-image: url('../../../assets/images/af-1/bg.png');
	}
</style>
